<template>
  <v-card :loading="loading">
    <v-toolbar>
      <v-toolbar-title>Agendamentos</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-select
        v-if="userData && userData.accessProfile.id === 1"
        :loading="loadingCompanyBranches"
        label="Ver agendamentos de:"
        v-model="selectedCompanyBranch"
        hide-details
        class="required mr-2"
        :items="filteredCompanyBranches"
        item-value="id"
        item-text="fantasyName"
        @change="loadSchedules()"
      ></v-select>
      <v-btn
        v-if="checkPermission('insert-schedules')"
        text
        @click="newSchedule()"
      >
        <v-icon left>mdi-plus</v-icon> Adicionar
      </v-btn>
    </v-toolbar>

    <v-row class="fill-height ma-1">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Hoje
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Dia</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Semana</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Mês</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            locale="pt-br"
            :interval-minutes="30"
            :first-time="'06:30'"
            :interval-format="intervalFormat"
            :events="events"
            event-color="primary"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          >
            <template v-slot:event="{ event }">
              <div>{{ event.name }}</div>
              <div>
                <p>
                  <strong>{{ event.status }}</strong>
                </p>
              </div>
            </template>

            <template
              v-slot:interval="{ date, time }"
              v-if="checkPermission('insert-schedules')"
            >
              <div class="d-flex justify-center align-center pt-2">
                <v-btn
                  class="new-schedule-btn"
                  text
                  @click="newSchedule(date, time)"
                  rounded
                  color="primary"
                >
                  <v-icon left>mdi-plus</v-icon> Agendar
                </v-btn>
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>

    <v-dialog v-model="scheduleDialog" max-width="700">
      <SchedulesForm
        :externalLoading="loadingScheduleData"
        dialog
        ref="scheduleForm"
        @close-dialog="scheduleDialog = false"
        @insert-completed="scheduleHasInserted"
        @update-completed="scheduleHasInserted"
        @reload-schedules="loadSchedules()"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import moment from "moment";
import SchedulesForm from "@/views/Schedules/SchedulesForm.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: { SchedulesForm },
  data: () => ({
    loading: false,
    loadingScheduleData: false,
    loadingCompanyBranches: false,
    scheduleDialog: false,
    schedules: [],
    selectedDate: null,
    selectedTime: null,
    selectedCompanyBranch: null,
    resultsPerPage: 15,
    totalRows: 0,
    filters: {
      currentPage: 1,
    },
    focus: "",
    type: "week",
    typeToLabel: {
      month: "Mês",
      week: "Semana",
      day: "Dia",
    },
    selectedEvent: {},
    selectedOpen: false,
    events: [],
    companyBranches: [],
    statusData: {
      pending: {
        name: "Pendente",
        color: "orange",
      },
      confirmed: {
        name: "Confirmado",
        color: "blue",
      },
      "waiting-for-service": {
        name: "Aguardando pelo serviço",
        color: "orange",
      },
      "patient-did-not-attend": {
        name: "Paciente não atendido",
        color: "red",
      },
      completed: {
        name: "Paciente atendido",
        color: "green",
      },
      canceled: {
        name: "Cancelado",
        color: "grey",
      },
    },
  }),
  methods: {
    ...mapActions("schedule", ["vLoadScheduleData"]),
    ...mapMutations("schedule", ["setFormData", "setMode", "clearStoreData"]),
    intervalFormat(locale) {
      return locale.time;
    },
    async loadSchedules() {
      try {
        this.loading = true;

        if (this.userData && this.userData.accessProfile.id === 1)
          await this.loadCompanyBranches();

        const params = { companyBranch: this.selectedCompanyBranch };

        let url = `/schedules`;

        const response = await this.$axios.get(url, { params });

        this.setSchedules(response.data);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    setSchedules(data) {
      const cpData = [...data];

      this.schedules = cpData;

      this.events = this.schedules.map((s) => {
        const schedulingStatus = this.statusData[s.status].name;

        return {
          id: s.id,
          name: s.Patient.Person.fullName,
          start: moment.utc(s.startDate).format("YYYY-MM-DD HH:mm:ss"),
          end: moment.utc(s.endDate).format("YYYY-MM-DD HH:mm:ss"),
          // color: this.generateEventColor(),
          timed: true,
          status: schedulingStatus,
        };
      });

      console.log(this.events);
    },
    async deleteSchedule(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Atenção",
          `Deseja realmente deletar o agendamento com o id n. ${id} ?`
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/schedules/${id}`;

        await this.$axios.delete(url);

        this.deleteScheduleFromTable(id);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    deleteScheduleFromTable(id) {
      const index = this.schedules.findIndex((p) => p.id === id);

      this.schedules.splice(index, 1);
    },
    editSchedule(id) {
      this.$router.push(`/agendamentos/${id}`);
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    // getEventColor(event) {
    //   return event.color;
    // },
    setToday() {
      this.focus = "";
      this.type = "day";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    scheduleHasInserted() {
      this.scheduleDialog = false;
      this.loadSchedules();
    },
    scheduleHasUpdated() {
      this.scheduleDialog = false;
      this.loadSchedules();
    },
    newSchedule(date, time) {
      if (date) this.setFormData({ key: "startDate", value: date });
      if (time) this.setFormData({ key: "startTime", value: time });
      this.setMode("insert");
      this.scheduleDialog = true;
    },
    async showEvent({ event }) {
      this.loadingScheduleData = true;
      this.setMode("view");
      this.scheduleDialog = true;

      await this.vLoadScheduleData(event.id);

      // await new Promise((r) => setTimeout(r, 2000));

      this.loadingScheduleData = false;
    },
    updateRange() {
      // const min = new Date(`${start.date}T00:00:00`);
      // const max = new Date(`${end.date}T23:59:59`);
      // const days = (max.getTime() - min.getTime()) / 86400000;
      // console.log(start);
      // console.log(end);
      // console.log(days);
      // console.log(min);
      // console.log(max);
    },
    // generateEventColor() {
    //   let n = (Math.random() * 0xfffff * 1000000).toString(16);
    //   return "#" + n.slice(0, 6);
    // },
    checkPermission(permissionKey) {
      return (
        this.userData.accessProfile.Permissions.findIndex(
          (p) => p.key === permissionKey
        ) !== -1
      );
    },
    async loadCompanyBranches() {
      this.loadingCompanyBranches = true;

      const response = await this.$axios.get(`/company-branches`);

      this.companyBranches = [...response.data];

      if (!this.selectedCompanyBranch)
        this.selectedCompanyBranch = this.companyBranches[0].id;

      this.loadingCompanyBranches = false;
    },
    handleError(error) {
      console.log(error);

      const errorHandled = errorHandler.treatError(error);

      this.$root.$errorDialog(errorHandled);
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
    filteredCompanyBranches() {
      const { companyBranches, userHasBranchRestriction } = this.userData;

      return userHasBranchRestriction
        ? this.companyBranches.filter((b) => companyBranches.includes(b.id))
        : this.companyBranches;
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  created() {
    this.loadSchedules();
  },
  beforeDestroy() {
    this.clearStoreData();
    this.schedules = [];
    this.selectedDate = null;
    this.selectedTime = null;
    this.selectedEvent = {};
    this.events = [];
  },
};
</script>

<style scoped>
.new-schedule-btn {
  display: none;
}

.v-calendar-daily__day-interval:hover .new-schedule-btn,
.v-calendar-daily__day-interval.hover .new-schedule-btn {
  display: inline-flex;
}
</style>
