var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Agendamentos")]),_c('v-spacer'),(_vm.userData && _vm.userData.accessProfile.id === 1)?_c('v-select',{staticClass:"required mr-2",attrs:{"loading":_vm.loadingCompanyBranches,"label":"Ver agendamentos de:","hide-details":"","items":_vm.filteredCompanyBranches,"item-value":"id","item-text":"fantasyName"},on:{"change":function($event){return _vm.loadSchedules()}},model:{value:(_vm.selectedCompanyBranch),callback:function ($$v) {_vm.selectedCompanyBranch=$$v},expression:"selectedCompanyBranch"}}):_vm._e(),(_vm.checkPermission('insert-schedules'))?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.newSchedule()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Adicionar ")],1):_vm._e()],1),_c('v-row',{staticClass:"fill-height ma-1"},[_c('v-col',[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" Hoje ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v("Dia")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v("Semana")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v("Mês")])],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","locale":"pt-br","interval-minutes":30,"first-time":'06:30',"interval-format":_vm.intervalFormat,"events":_vm.events,"event-color":"primary","type":_vm.type},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"change":_vm.updateRange},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
return [_c('div',[_vm._v(_vm._s(event.name))]),_c('div',[_c('p',[_c('strong',[_vm._v(_vm._s(event.status))])])])]}},(_vm.checkPermission('insert-schedules'))?{key:"interval",fn:function(ref){
var date = ref.date;
var time = ref.time;
return [_c('div',{staticClass:"d-flex justify-center align-center pt-2"},[_c('v-btn',{staticClass:"new-schedule-btn",attrs:{"text":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.newSchedule(date, time)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Agendar ")],1)],1)]}}:null],null,true),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.scheduleDialog),callback:function ($$v) {_vm.scheduleDialog=$$v},expression:"scheduleDialog"}},[_c('SchedulesForm',{ref:"scheduleForm",attrs:{"externalLoading":_vm.loadingScheduleData,"dialog":""},on:{"close-dialog":function($event){_vm.scheduleDialog = false},"insert-completed":_vm.scheduleHasInserted,"update-completed":_vm.scheduleHasInserted,"reload-schedules":function($event){return _vm.loadSchedules()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }